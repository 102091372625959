.homeButtonContainer {
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    display: flex;
}

.technicalSkills ul{
    list-style-type: none;
    padding: 0;
    
}

.technicalSkills ul li strong {
    font-size: 1rem;
}

.technicalSkills ul li {
    font-size: 0.98rem;
}

.careerHighlights ul {
    padding-left: 0.75rem;
}

.leftSide {
    width: 42%;
}

.rightSide {
    width: 58%;
}

@media all and (max-width: 915px) {
    .homeButtonContainer {
     flex-direction: column;
    }
    .leftSide {
        width: 100%;
    }
    .rightSide {
        width: 100%;
        margin-top: 1.5rem;
    }
}