.listContainer  {
  font-family: sans-serif;
  background-color: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.listWrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }

  .todoHeaderContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-left: 1.5rem;
    align-items: center;
    margin-top: 0.75rem;
  }

  .todoHeaderContainer svg {
    font-size: 1.25rem;
  } 

 .toDoListContainer {
  width: 100%;  
  max-width: 455px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
button {
    cursor: pointer;
  }

  .visuallyHidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    white-space: nowrap;
  }

  .positionUpdate {
    position: relative !important;
  }
  .textLarge {
    font-size: 32px;
  }
  .textSmall {
    font-size: 24px;
  }

 
  @media (max-width: 510px) {
    .listWrapper {
      width: 100%;
    }
    header {
      justify-content: center;
    }
  }
  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 42px;
  }
  .todoheroSection {
    border: 1px solid var(--darkgrey);
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-self: center;
    width: 90%;
    max-width: 455px;
    padding: 12px;
    border-radius: 11px;
  }
  .blueCircle {
    background-color: var(--blue);
    width: 150px;
    height: 150px;
    border-radius: 75px;
    font-size: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .form {
    align-self: center;
    width: 91%;
    max-width: 455px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 26px;
  }
  .form label {
    width: 90%;
  }
  .form input {
    background-color: var(--black);
    color: var(--white);
    width: 100%;
    height: 50px;
    outline: none;
    border: 1px solid var(--darkgrey);
    border-radius: 11px;
    padding: 12px;
  }
  .form button {
    width: 10%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    background-color: var(--blue);
    border: none;
  }
  .todoList {
    align-self: center;
    width: 100%;
    max-width: 525px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 27px auto;
    gap: 27px;
  }
  .todoItem,
  .editForm input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
    max-width: 455px;
    border: 1px solid var(--darkgrey);
    font-size: 16px;
    background-color: var(--black);
    color: #fff;
    padding: 12px;
  }
  .editForm input {
    outline: transparent;
    width: calc(100% - 14px);
    height: calc(100% - 12px);
    border: transparent;
  }
  .todoItemsLeft,
  .todoItemsRight {
    display: flex;
    align-items: center;
  }
  .todoItemsLeft {
    background-color: transparent;
    border: none;
    color: var(--white);
    gap: 12px;
    font-size: 16px;
  }
  .todoItemsRight {
    gap: 4px;
  }
  .todoItemsRight button {
    background-color: transparent;
    color: var(--white);
    border: none;
  }
  .todoItemsRight button svg {
    fill: var(--grey);
  }