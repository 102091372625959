/* Footer */

Footer {
    display: flex;
    position: relative;
    width: 100%;
    bottom: 0;
}

.footerStyle {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.foot1, .foot2 {
    margin: 0 auto;
}

.timing {
    font-size: 1rem;
    color: var(--black);
    padding-top: 1rem;
}

.spaceForTiming {
    padding-left: 4rem;
}

@media all and (max-width: 767px) {
    .foot2 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
    .spaceForTiming {
        padding-left: 0;
        padding-top: .25rem;
    }
}