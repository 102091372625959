.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  background-color: var(--blue);
  padding: 20px;
  margin: 1rem auto;
}

@media all and (max-width: 500px) {
  .mainContainer {
    width: 20rem;
  }
}

.rulesView {
  align-self: flex-end;
  margin-top: auto;
}

@media screen and (max-width: 576px) {
  .rulesView {
    margin-bottom: 40px;
  }
}

.popUpView {
  display: flex;
  flex-direction: column;
  /* height: 40vw; */
  justify-content: space-between;
  padding: 10px;
}

@media screen and (max-width: 576px) {
  .popUpView {
    height: 260px;
    width: 100%;
  }
}

.popUpImage {
  width: 100%;
  height: 90%;
  margin-top: auto;
}

.trigger-button {
  width: 4rem;
  height: 2rem;
  padding: 0.75rem;
  color: var(--black);
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-style: none;
  outline: none;
}

.trigger-button-close {
  background-color: transparent;
  width: 20px;
  height: 20px;
  font-size: 18px;
  border-style: none;
  outline: none;
  align-self: flex-end;
  margin-bottom: 5px;
}

.rulesTitle {
  font-size: 2rem;
  color: var(--black);
  text-align: center;
  padding-bottom: 0.5rem;
  margin-top: -2rem;
}

.rules {
  font-size: 1.5rem;
  text-align: center;
  color: var(--black);
}